import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/issueIndex";
export const _frontmatter = {
  "title": "Volume 4, Issue 2"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Published: May, 2020`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "./president-letter/"
          }}>{`President's letter`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "./editorial/"
          }}>{`Editorial`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "./editorial-note-on-opinion-articles/"
          }}>{`Editorial Note On Opinion Articles`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "./afpi-karnataka-news/"
          }}>{`AFPI Karnataka News`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Article: `}<a parentName="p" {...{
            "href": "./community-health/"
          }}>{`Community Health: Stopping the Clock on our Ever-Expanding World`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Article: `}<a parentName="p" {...{
            "href": "./life-long-learning-strategies/"
          }}>{`Life-Long Learning Strategies for Family Physicians`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Practice Experience: `}<a parentName="p" {...{
            "href": "./dilemmas-of-a-gp/"
          }}>{`Dilemmas of a GP`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Practice Experience: `}<a parentName="p" {...{
            "href": "./food-for-thought/"
          }}>{`Food For Thought`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Opinion: `}<a parentName="p" {...{
            "href": "./healthcare-delivery-system-in-india-my-reflections/"
          }}>{`Healthcare Delivery System in India - My Reflections`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Republished article: `}<a parentName="p" {...{
            "href": "./ethical-challenges-of-digital-health-technologies-aadhaar-india/"
          }}>{`Ethical challenges of digital health technologies: Aadhaar, India`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Article: `}<a parentName="p" {...{
            "href": "./paradigm-shift-in-cancer-management/"
          }}>{`Paradigm Shift in Cancer Management - Molecular Therapy`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`News: `}<a parentName="p" {...{
            "href": "./spice-route-india-cafe/"
          }}>{`Spice Route India Cafe: Voices of Young Family doctors from across the country`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      